@import "base";
@import "modal";
.main {
	&--underline {
		border-bottom: 1px solid #ddd;
	}
}
.header {
	display: none;
	width: 100%;
	position: absolute;
	top: 0;
	z-index: 2;
	&__logo {
		display: block;
		width: 144px;
		margin: 5px auto;
		> img {
			width: 100%;
		}
	}
	&__price {
		width: 100%;
		position: absolute;
		top: 50px;
		color: white;
		margin: 0;
		text-align: center;
	}
}

/* iPad vertical size */
@include media($min-sm) {
	.header {
		display: block;
		width: 100%;
		position: absolute;
		z-index: 2;
		&__logo {
			display: block;
			width: 157px;
			position: absolute;
			top: 10px;
			left: $gutter;
			z-index: 2;
		}
		&__container {
			@include clearfix;
			position: relative;
			margin: auto;
		}
		&__price {
			//top: $gutter * 6;
			left: 0;
			z-index: 1;
			color: white;
			text-align: center;
		}
	}
}

/* Narrow PC & holizontal iPad size */
@include media($min-md) { 
	.header {
		&__logo {
			left: 0;
		}
		&__container {
			width: 960px;
		}
		&__price {
			top: 26px;
		}
	}
}

.header-nav {
	width: 100%;
	margin-top: 32px;
	text-align: center;
	&__list {
		@include clearfix;
		> li {
			display: inline-block;
			/*width: calc(100% / 3);
			float:left;*/
			text-align: center;
		}
	}
	&__link {
		display: inline-block;
		color: white;
		font-size: 14px;
		padding: .3rem $gutter;
		&:hover, &:active, &:visited {
			color: white;
		}
		
		&.active {
			display: none;
		}
		&--signup {
			border: 1px solid white;
			&:hover, &:active {
				text-decoration: none;
				background-color: rgba(255,255,255,.2);
			}
		}
	}
}

/* iPad vertical size */
@include media($min-sm) {
	.header-nav {
		text-align: right;
		width: calc(100% / 3 * 2);
		position: absolute;
		top: $gutter;
		right: $gutter;
		z-index: 3;
		margin-top: 0;
		&__link {
			color: white;
			font-size: 16px;
			padding: .5rem $gutter;
			&:hover, &:active, &:visited {
				color: white;
			}
			&--signup {
				border: 1px solid white;
				margin-left: $gutter;
			}
		}
	}
}

/* Narrow PC & holizontal iPad size */
@include media($min-md) { 
	.header-nav {
		width: calc(100% / 3);
		right: 0;
		padding: 0;
		&__link {
			font-size: 18px;
		}

	}
}

.footer {
	width: 100%;
	text-align: center;
	&__container {
		color: white;
		background-color: #2c9fdd;
		padding: $gutter ;
		&--nav {
			@include clearfix();
			background-color: white;
			color: #666;
			padding: 0;
			a {
				color: $corp-black;
				&:hover,
				&:active {
					color: $corp-black;
					text-decoration: none;
				}
			}
		}
	}
	&__title {
		color: white;
		font-size: 30px;
	}
	&__lead {
		font-size: 21px;
		margin-bottom: 0;
		padding: 15px 0;
	}
	&__aside {
		padding: 15px;	
	}
	&__comments {
		color: rgb(170, 179, 188);
		font-size: 13px;
		margin-bottom: 1rem;
	}
	&__email {
		color: rgb(152, 160, 166);
		font-size: 14px;
		margin-bottom: 1rem;
	}
	&__copyright {
		color: rgb(152, 160, 166);
		font-size: 14px;
	}
	&__brand {
		@include clearfix;
		width: 100%;
		padding: $gutter;
	}
	&__brand-container {
		width: 50%;
		float: left;
		margin-top: .5rem;
		text-align: left;
	}
	&__brand-logo {
		display: block;
		width: 160px;
		> img {
			width: 100%;
		}
	}
	&__brand-copy {
		margin-bottom: 0;
	}
	&__brand-digicert {
		display: table-cell;
		float: left;
		margin-top: 3px;
	}
}

/* iPad vertical size */
@include media($min-sm) {
	.footer {
		&__container {
			padding: 60px 30px;
			&--nav {
				padding: 0;
			}
		}
		&__lead {
			font-size: 18px;
			padding: 30px 0;
		}		
	}	
}
/* Narrow PC & holizontal iPad size */
@include media($min-md) {
	.footer {
		&__container {
			&--nav {
				padding: ($gutter * 2) 8.3333%;
			}
		}
		&__lead {
			font-size: 21px;
			padding: 60px 0;
		}
		&__brand {
			width: 200px;
			float:left;
			padding: 0;
		}
		&__brand-container {
			width: 160px;
			margin-bottom: $gutter; 
		}
		&__brand-digicert{
			text-align: left;
			padding-left: $gutter;
		}
		&__nav {
			width: calc((100% - 250px) / 5);
			float: left;
		}
	}	
}

.footer-nav {
	text-align: left;
	font-size: 13px;
	&__caption {
		display: block;
		width: 100%;
		background-color: rgba(70, 140, 203, .25);
		padding: .5rem $gutter;
	}
	&__list {
		@include clearfix;
		li {
			width: 50%;
			float: left;
			border-color: #ddd;
			border-style: none solid solid none;
			border-width: 1px;
			&:nth-of-type(2n) {
				border-right: none;
			}
			&:last-of-type {
				border-bottom: none;
			}
			&:nth-of-type(2n+1) {
				&:nth-last-of-type(2) {
					border-bottom: none;
				}
			}

			> a {
				display: table;
				width: 100%;
				height: 60px;
				padding: 0 $gutter;
				> span {
					display: table-cell;
					vertical-align: middle;
					.sns {
						width: 20px;
						margin-right: .5em;
						margin-top: -5px;
						margin-bottom: -5px;
					}
				}
			}

		}
		border-bottom: 1px solid #ddd;
	}
}
/* Narrow PC & holizontal iPad size */
@include media($min-md) {
	.footer-nav {
		&__caption {
			width: auto;
			background-color: transparent;
			margin-bottom: .5em;
			padding: 0;
		}
		&__list {
			border-bottom: none;
			> li {
				width: auto;
				float: none;
				border: none;
				margin-bottom: .5rem;
				> a {
					display: block;
					width: auto;
					height: auto;
					padding: 0;
					> span {
						display: inline;
					}
				}
			}
		}	
	}
}
.header-main {
	width: 100%;
	@include background(url("//v.fastcdn.co/u/557652a6/20751146-0-flat-map.jpg") center no-repeat);
	background-size: cover;
	background-attachment: fixed;
	&--main {
		width: 100%;
	}
	&__bg-overlay {
		background-color: #2c9fdd;
		opacity: 0.90361445783133;
		padding-bottom: $gutter;
		&--corporate {
			background-color: #2d2f3d;
		}
	}
	&__container {
		padding: ($gutter * 5) $gutter 0;
		text-align: center;
	}
	&__title {
		color: white;
		font-weight: 300;
		margin: auto;
		padding-bottom: $gutter;
		> span {
			color: white;
			font-style: normal;
		}
	}
	&__lead {
		color: white;
		padding: 0 0 $gutter 0;
		margin-bottom: 0;
	}
	&__login {
		position: absolute;
		top: $gutter;
		right: $gutter;
		z-index: 2;
		color: white;
		font-size: 18px;
		&:hover,
		&:active,
		&:visited {
			color: white;
		}
	}
}

/* SP horizontal size */
@include media($min-xs) { 
	.header-main {
		padding-bottom: 0;
		&__bg-overlay {
			padding-bottom: $gutter;
		}
		&__container {
			background-position: center 20px;
			background-size: inherit;
			padding: ($gutter * 5) ($gutter * 2) 0;
		}
		&__title {
			font-size: 38px;
			padding-bottom: 35px;
		}
		&__lead {
			font-size: 16px;
			padding: 0 0 35px 0;
		}
		&__login {
			right: $gutter * 2;
		}
	}
}

/* iPad vertical size */
@include media($min-sm) {
	.header-main {
		&__container {
			padding-top: 140px;
			padding-bottom: 30px;
		}
		&__title {
			width: 100%;
			font-size: 42px;
			padding: 0 $gutter;
			padding-bottom: 55px;
		}
		&__lead {
			font-size: 20px;
			padding: 0 0 55px 0;
		}
		&__login {
			font-size: 22px;
		}
	}
}
/* Narrow PC & holizontal iPad size */
@include media($min-md) { 
	.header-main {
		&__container {
			padding: ($gutter * 6) 0 ($gutter * 4);		
			&--index{
				padding-top: 200px;
			}
		}
		&__title {
			width: 960px;
			/* width: 800px */
			font-size: 50px;
			.is-larger {
				font-size: 40px;
			}
		}
		&__lead {
			width: 960px;
			margin: auto;
		}		
	}
}
/* PC size */
@include media($min-lg) { 
	.header-main {
		&__login {
			left: calc(50% + 512px - 52px)
		}
	}

}
body{
.email {
	width: auto;
	margin: auto;
	padding: 15px 0;
	&__field {
		margin-bottom: 15px;
		> input {
			background-color: #fff;
		    border: none;
		    padding: 9px 10px 10px 10px;
		    @include border-top-radius(3px);
		    @include border-bottom-radius(3px);
		    @include border-right-radius(3px);
		    @include border-left-radius(3px);
		    margin: 0;
		    font-size: 15px;
		    line-height: 19px;
		    width: 100%;
		    height: 43px;
		}
	}
	&__submit {
	}
	&__msg {
		font-size: 18px;
		font-weight: bold;
		background-color: $corp-orange;
		//background: rgba(255,255,255,.5);
		border-radius: 3px;
		margin-top: .5rem;
		padding: .5rem 15px;
		color: white;
		//color: $corp-orange;
	}
	&__notice {
		color: white;
		font-size: 16px;
		> a {
			color: white;
			text-decoration: underline;
		}
	}
}

/* SP horizontal size */
@include media($min-xs) { 
	.email {
		width: 500px;
		padding: 0;
			&__container {
				@include clearfix();
		}
		&__field {
			width: calc(100% - 175px);
			float: left;
			margin-bottom: 0;
			> input {
			    @include border-right-radius(0);
			}
		}
		&__submit {
			width: 175px;
			float: right;
		}
		&__notice {
			font-size: 20px;
		}
	}
}
}
/* Narrow PC & holizontal iPad size */
@include media($min-md) {
}
.email-submit {
	&__btn {
		width: 100%;
		height: 43px;
		background-color: #e1262d;
		@include border-top-radius(3px);
		@include border-right-radius(3px);
		@include border-bottom-radius(3px);
		@include border-left-radius(3px);
		-webkit-appearance: none;
       	color: white;
		font-size: 15px;
		font-family: Lato;
		font-weight: 900;
		line-height: 17px;
    	&:hover, &:focus {
	    	color: white;
	    	background-color: #aab3bc;    	
    	}
    	&:active {
    		outline: none;
			-webkit-box-shadow: 0 3px 5px rgba(0,0,0,0.125) inset;
		    -moz-box-shadow: 0 3px 5px rgba(0,0,0,0.125) inset;
  			box-shadow: 0 3px 5px rgba(0,0,0,0.125) inset;
		}
	}
}
/* iPad vertical size */
@include media($min-sm) {
	.email-submit {
		&__btn {
			@include border-left-radius(0);			
		}
	}
}
body{
.policy {
	width: 100%;
	&__text {
		color: white;
		font-size: 15px;
		padding: 15px;
		> a {
			color: white;
			text-decoration: underline;
		}
	}
}
}

.title {
	font-size: 28px;
	line-height: 1.35;
}
/* iPad vertical size */
@include media($min-sm) {
	.title {
		font-size: 32px;
	}
}
/* Narrow PC & holizontal iPad size */
@include media($min-md) {
	.title {
		font-size: 36px;
	}
}

.title-counter {
	counter-reset: titleCounter;
	&__increment {
		&::before {
			counter-increment: titleCounter;
			content: counter(titleCounter) ". ";
		}
	}
}
.underline-wrapper {
	border-bottom: 1px solid $light-gray;
}
/* Narrow PC & holizontal iPad size */
@include media($min-md) {
	.underline-wrapper {
		width: 500px;
		margin: auto;
		border-width: 2px;
		> article, > div, > nav {
			width: 960px;
			margin-left: calc((500px - 960px) / 2);
			overflow: visible;		
		}
	}	
}