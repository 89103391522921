@import "common";
.black-bar {
    background-color: #2c3940;
    color: white;
    padding: .5rem;
    text-align: center;

    &__text {
        font-size: 18px;
        margin-bottom: 0;

        a {
            color: inherit;
            // overwrite rule in sp_common.min.css
            text-decoration: underline !important;
        }
    }

    @media (max-width: 767px) {
        &:first-child {
            padding-top: 55px;
        }
    }
}
.coins-nav {
	margin: auto;
	padding-top: $gutter * 3;
	&__list {
		@include clearfix;
		list-style: none;
		> li {
			display: block;
			width: 25%;
			float: left;
			padding: ($gutter * 2) 0;
			&:nth-of-type(5) {
				margin-left: calc(25% / 2);
			}
		}
	}
	&__link {
		display: block;
		width: 75px;
		margin: auto;
		text-align: center;
		> svg {
			width: 50px;
			margin: auto;
		}
		> dl {
			> dt, dd {
				color: $light-gray;
				font-weight: bold;
			}
			> dt {
				font-size: $fs-larger;
				min-height: 4em;
				padding-top: $gutter;
			}
			> dd {
				font-size: $fs-medium;
			}
		}
	}
	&__notice {
		display: inline-block;
		color: $light-gray;
		font-size: $fs-medium;
		margin-top: $gutter;
		text-align: center;
	}
}
/* SP horizontal size */
@include media($min-md) {
	.coins-nav {
		&__list {
			display: flex;
			> li {
				flex: auto;
				&:nth-of-type(5) {
					margin-left: 0;
				}
			}
		}
		&__link {
			width: 90px;
			> svg {
				width: 75px;
			}
			> dl {
				> dt {
					font-size: $fs-large;
				}
				> dd {
					font-size: $fs-larger;
				}
			}
		}
	}	
}
.featured {
	width: 100%;
	border-bottom: 1px solid $white-gray;
	padding: 30px;
	text-align: center;
	&__title {
		color: $corp-black;
		font-size: 21px;
		font-weight: bold;
	}
	&__list {
		margin-top: $gutter;
		padding-bottom: $gutter;
		> li {
			display: block;
			height: 32px;
			overflow: hidden;
			position: relative;
			margin-bottom: 15px;
			img {
				position: relative;
			}
		}
	}
	&__item {
		&--tech-crunch {
			> img {
				width: 140px;
				top: -5px;
			}
		}
		&--new-york-times {
			> img {
				width: 148px;
				top: 4px;
			}
		}
		&--wall-street-journal {
			> img {
				width: 160px;
				top: 7px;
			}
		}
		&--venture-beat {
			> img {
				width: 145px;
				top: -5px;
				left: 0px;
			}
		}
		&--reuters {
			> img {
				width: 160px;
				top: -20px;
			}
		}
		&--coindesk {
			> img {
				width: 155px;
				top: -4px;

			}
		}
		&--cnbc {
			svg {
				width: 158px;
				top: 0;
			}
		}
	}
}
/* SP horizontal size */
@include media($min-xs) {
	.featured {
		margin-bottom: 0;
		&__list {
			@include clearfix();
			margin-top: 30px;
			> li {
				width: 33.3333%;
				float: left;
				margin-bottom: 0;
			}
		}
	}
}

/* iPad vertical size */
@include media($min-sm) {
	.featured {
		border-bottom: none;
		&__item {
			&--venture-beat {
				> img {
				}
			}
		}
	}
}

/* Narrow PC & holizontal iPad size */
@include media($min-md) {
	.featured {
		width: 960px;
		margin: auto;
		&__list {
			> li {
				width: calc(100% / 3);
			}
		}
	}
}

.advantages-wrapper {
    position: relative;
	background-color: #f4f6f6;
	padding-top: $gutter;
	&--transparent {
		background-color: white;
		border-top: #f4f6f6 solid 1px;
	}
}
/* iPad vertical size */
@include media($min-sm) {
    .advantages-wrapper {
        position: relative;
        padding-bottom: $gutter * 2;
    }
}
.advantage1 {
	width: 100%;
	background-color: white;
	border-bottom: 1px solid $white-gray;
	margin: auto;
	padding: 30px 15px;
	text-align: center;
	&__title {
		color:$corp-black;
		font-size: 22px;
	}
	&__lead {
		color: $light-gray;
		font-size: 15px;
		padding: 30px 15px 0;
	}
	&__list {
		> li {
			> dl {
				> dt {
					color: rgb(225, 38, 45);
					font-size: 37px;
					font-weight: bold;
					padding: 15px 15px 0;
				}
				> dd {
					color: rgb(170, 179, 188);
					font-size: 15px;
					padding: 0 15px 15px;
				}
			}
		}
	}
}
/* iPad vertical size */
@include media($min-sm) {
	.advantage1 {
		position: relative;
		@include background(url("//v.fastcdn.co/u/a1ab1db8/13275603-0-RECTANGLE.png") top center no-repeat);
		background-size: cover;
		width: 760px;
		border-bottom: none;
		margin: auto;
		padding: ($gutter * 2) ($gutter * 2) ($gutter * 2); 
		&::after {
			content: "";
			display: block;
			width: 100%;
			height: $gutter * 4;
			position: absolute;
			left: 0;
			bottom: -($gutter);
			z-index: 10000;
			@include background(url("//v.fastcdn.co/u/a1ab1db8/13275603-0-RECTANGLE.png") center -300px no-repeat);
			background-size: cover;
			background-color: #f4f6f6;
		}
		&__lead {
			margin-bottom: 0;
			padding-bottom: $gutter * 2;
		}
		&__list {
			@include clearfix();
			> li {
				width: 33.3333%;
				float: left;
				> dl {
					> dt {
						padding: $gutter;
					}
					> dd {
						padding: $gutter;
					}
				}
			}
		}
	}
}

/* Narrow PC & holizontal iPad size */
@include media($min-md) {
	.advantage1 {
		width: 960px;
		padding: ($gutter * 5) ($gutter * 2);
		&::after {
			background-position: center -396px;
		}
	}
}
.advantage0 {
    width: 100%;
    margin: auto;
    //border-bottom: 1px solid $white-gray;
    padding: 15px 15px 30px;
    text-align: center;

    @include background(url("//msm07s-cdn.bitflyer.com/pub/common/theme/images/toppage_US_HBJ.png") right 240px no-repeat);
    background-size: 120%;
    padding: 30px;
    padding-top: 550px;
    background-position: 120% 0%;

    &__title {
        color: $corp-black;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
    }

    &__lead {
        color: $light-gray;
        font-size: 15px;
        padding: 30px 15px 0;
        text-align: center;
    }

    &__list {
        > li {
            padding: 10px;

            > dl {
                > dt {
                    color: $corp-black;
                    font-size: 20px;
                    font-weight: bold;
                }

                > dd {
                    color: rgb(170, 179, 188);
                    font-size: 15px;
                }
            }
        }
    }
}

.appImage {
    position: relative;
}

.advantage0eu {
    width: 100%;
    margin: auto;
    //border-bottom: 1px solid $white-gray;
    padding: 15px 15px 30px;
    text-align: center;

    @include background(url("//msm07s-cdn.bitflyer.com/pub/common/theme/images/toppage_EU_HBJ.png") right 240px no-repeat);
    background-size: 120%;
    padding: 30px;
    padding-top: 550px;
    background-position: 120% 0%;

    &__title {
        color: $corp-black;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
    }
    &__lead {
        color: $light-gray;
        font-size: 15px;
        padding: 30px 15px 0;
        text-align: center;
    }
    &__list {
        > li {
            padding: 10px;

            > dl {
                > dt {
                    color: $corp-black;
                    font-size: 20px;
                    font-weight: bold;
                }
                > dd {
                    color: rgb(170, 179, 188);
                    font-size: 15px;
                }
            }
        }
    }
}

.advantage2 {
    width: 100%;
    margin: auto;
    //border-bottom: 1px solid $white-gray;
    padding: 15px 15px 30px;
    text-align: center;

    @include background(url("//msm07s-cdn.bitflyer.com/pub/common/theme/images/laptop_lightning.png") 64px 240px no-repeat);
    background-size: 65%;
    padding: 30px;
    padding-top: 300px;
    background-position: 50% 0%;

    &--exchange {
        @include background(url("//msm07s-cdn.bitflyer.com/pub/common/theme/images/toppage_US_HBJ.png") right 240px no-repeat);
        background-size: 65%;
        padding: 30px;
        padding-top: 300px;
        background-position: 50% 0%;
    }

    &__title {
        color: $corp-black;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
    }

    &__lead {
        color: $light-gray;
        font-size: 15px;
        padding: 30px 15px 0;
        text-align: center;
    }

    &__list {
        > li {
            padding: 10px;

            > dl {
                > dt {
                    color: $corp-black;
                    font-size: 20px;
                    font-weight: bold;
                }

                > dd {
                    color: rgb(170, 179, 188);
                    font-size: 15px;
                }
            }
        }
    }
}
.advantage0, .advantage0eu {
	&__title {
		padding-bottom: 15px;
	}
}

/* iPad vertical size */
@include media($min-sm) {
    .advantage0 {
        @include background(url("//msm07s-cdn.bitflyer.com/pub/common/theme/images/toppage_US_HBJ.png") right 240px no-repeat);
        background-size: 50%;
        padding: 30px;
        padding-top: 320px;
        background-position: 50% 0%;

        &__lead {
            margin-bottom: 0;
            padding-top: $gutter;
            padding-bottom: $gutter * 4;
        }

        &__list {
            padding-left: 0;

            > li {
                > dl {
                    > dt {
                        font-size: 24px;
                    }
                }
            }
        }
    }

    .appImage {
        position: relative;
    }

    .advantage0eu {
        @include background(url("//msm07s-cdn.bitflyer.com/pub/common/theme/images/toppage_EU_HBJ.png") right 240px no-repeat);
        background-size: 50%;
        padding: 30px;
        padding-top: 320px;
        background-position: 50% 0%;

        &__lead {
            margin-bottom: 0;
            padding-top: $gutter;
            padding-bottom: $gutter * 4;
        }

        &__list {
            padding-left: 0;

            > li {
                > dl {
                    > dt {
                        font-size: 24px;
                    }
                }
            }
        }
    }

    .advantage2 {
        @include background(url("//msm07s-cdn.bitflyer.com/pub/common/theme/images/laptop_lightning.png") 64px 240px no-repeat);
        background-size: 50%;
        padding: 30px;
        padding-top: 320px;
        background-position: 50% 0%;

        &--exchange {
            @include background(url("//msm07s-cdn.bitflyer.com/pub/common/theme/images/toppage_US_HBJ.png") right 240px no-repeat);
            background-size: 50%;
            padding: 30px;
            padding-top: 320px;
            background-position: 50% 0%;
        }

        &__lead {
            margin-bottom: 0;
            padding-top: $gutter;
            padding-bottom: $gutter * 4;
        }

        &__list {
            padding-left: 0;

            > li {
                > dl {
                    > dt {
                        font-size: 24px;
                    }
                }
            }
        }
    }

    .advantage0,
    .advantage0eu {
        &__title {
            padding-bottom: 60px;
        }
    }
}

/* Narrow PC & holizontal iPad size */
@include media($min-md) {
    .advantage0,
    .advantage0eu,
    .advantage2 {
        width: 960px;
        min-height: 340px;
        padding: ($gutter * 6) ($gutter * 2) ($gutter * 6);
        text-align: left;
    }

    .appImage {
        position: relative;
    }

    .advantage0 {
        @include background(url("//msm07s-cdn.bitflyer.com/pub/common/theme/images/toppage_US_HBJ.png") right 195px no-repeat);
        background-size: 50%;

        &__list {
            padding-left: 0;

            > li {
                width: 400px;
            }
        }
    }

    .advantage0eu {
        @include background(url("//msm07s-cdn.bitflyer.com/pub/common/theme/images/toppage_EU_HBJ.png") right 195px no-repeat);
        background-size: 50%;

        &__list {
            padding-left: 0;

            > li {
                width: 400px;
            }
        }
    }

    .advantage2 {
        @include background(url("//msm07s-cdn.bitflyer.com/pub/common/theme/images/laptop_lightning.png") 64px 240px no-repeat);
        background-size: 50%;

        &--exchange {
            @include background(url("//msm07s-cdn.bitflyer.com/pub/common/theme/images/laptop_hanbaijo.png") right 240px no-repeat);
            background-size: 50%;
        }

        &__list {
            padding-left: 520px;

            > li {
                width: 330px;
            }

            &--exchange {
                padding-left: 0;

                > li {
                    width: 470px;
                }
            }
        }
    }
}
.sns {
	&__title {
		font-size: 30px;
	}
	&__list {
		width: 160px;
		margin: auto;
		margin-top: 15px;
		@include clearfix();
		> li {
			width: 50%;
			float: left;
			> a {
				color: $corp-black;
			}
		}
	}
	&__item {
		&--twitter {
			> a {
				font-size: 45px;
			}
		}
		&--facebook {
			> a {
				font-size: 40px;
				margin-top: 2px;
			}
		}
	}
}
