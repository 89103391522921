.login-container {
    padding: 0;
    margin: 0;
    .form-signin {
        padding: 0;
        border: none;
        box-shadow: none;
        .modal {
            input[type="text"] {
                font-size: 16px;
                height: auto;
                padding: 7px 9px;
                margin-bottom: 15px;
                box-shadow: inset 0 1px 2px rgba(0,0,0,.05);
                background: #fff;
            }
            .btn-lg {
                width: 100%;
                max-width: 300px;
                padding: 10px 16px;
                font-size: 18px;
                line-height: 1.33;
                border-radius: 6px;
            }
            .btn-primary {
                background: #468ccb;
            }
        }
    }
}