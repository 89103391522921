@import "bourbon/bourbon";
@import "neat/neat";

$blob-root: "//msm07s-cdn.bitflyer.com/pub/lp/bitcoin-start-guide/";

$container-width: 1024px;
$header-height: 640px;
$header-sm-height: 448px;
$header-xs-height: 300px;
$header-mi-height: 250px;
$sp-horizontal-text-width: 480px;
$gutter: 14px;
$xs-gutter: 7px;

/* Font-family */
//$font-family-base: "Hiragino Kaku Gothic ProN","メイリオ", sans-serif;
$font-family-base: Arial, Helvetica, "Liberation Sans", FreeSans, sans-serif;
$font-family-yu: "Yu Gothic",YuGothic,"メイリオ","Hiragino Kaku Gothic ProN",sans-serif;

/* Font size */
$fs-smallest: 8px;
$fs-small: 10px;
$fs-smaller: 12px;
$fs-medium: 14px;
$fs-larger: 16px;
$fs-large: 18px;
$fs-xLarge: 20px;
$fs-xxLarge: 24px;
$fs-xxxLarge: 28px;
$fs-xxxxLarge: 32px;
$fs-xxxxxLarge: 36px;
$fs-titleLarge: 44px;

/* Color */
$corp-black: #333;
$corp-gray: #555;
$light-gray: rgb(170, 179, 188);
$corp-blue: #468ccb;
$corp-orange: #ee7f49;
$custom-blue: #29a4e2;
$white-gray: #eee;

/* letter-spacing */
@mixin letter-spacing($size) {
	letter-spacing: $size;
}

/*
Media Query
*/
$max-lg: min-width 0;
$max-md: max-width 1279px;
$max-sm: max-width 1023px;
$max-xs: max-width 767px;
$max-mi: max-width 479px;
$min-lg: min-width 1280px;
$min-md: min-width 1024px;
$min-sm: min-width 768px;
$min-xs: min-width 480px;
$min-mi: min-width 0;

@mixin set-title-font() {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
}

@mixin set-ul-style($type) {
	list-style: $type outside;
	padding-left: 1em;
}

/* reset */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, input, button, select, textarea,
input[type="text"], input[type="password"], input[type="email"], input[type="input"],
body.responsive, body#sub.sp {
    font-family: 'Open Sans', sans-serif;
}
h1,h2,h3,h4,h5,h6 {
	margin: 0;
	line-height: 1.5;
	text-shadow: none;
}
ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
sup {
	font-size: 75%;
	padding-left: .5em;
}
 
a {
	color: #2c9fdd;
	text-decoration: none;
	&:hover, &:active, &:visited {
		color: #2c9fdd;
	}
}
p, a, li, dt, dd, small {
	line-height: 1.45;
}

.bgimg-alt {
	position: absolute;
	display: block;
	text-indent: -9999px;
}

html {
}

body {
    min-width: 0;
    color: $corp-black;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    background-image: none;
    background-color: white;
}
input, button {
	outline: 0;
	&:hover, &:active, &:focus {
		outline: 0;
	}
}
input {
	&[type="text"],
	&[type="password"]{
		color: #525252;
	}
}

.hidden-xs {
	display: none;
}
.hidden-sm {
	display: none;
}

.ls-half {
	@include letter-spacing(-0.5em);
} 
.clearfix {
	@include clearfix;
}
@include media($min-sm) {
	.hidden-xs {
		display: initial;
	}
}
@include media($min-md) {
	.hidden-xs {
		display: initial;
	}
	.hidden-sm {
		display: initial;
	}
}
.title, p, li, dt, dd, address, address > a {
    font-family: 'Open Sans', sans-serif;
}
.title {
	font-weight: 300;
}